
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { TentStoreMixin } from "@/mixins/Stores/TentStoreMixin";
import { Tent } from "@/config/Modules";

@Component({
  components: {},
})
export default class TentShow extends mixins(TentStoreMixin) {
  protected loadTent(): void {
    if (this.$route?.params?.id) {
      this.findOneTentItemAction({
        resource: [Tent.resource, this.$route.params.id].join("/"),
      });
    }
  }

  created() {
    this.loadTent();
  }
}
